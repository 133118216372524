<template>
  <form @submit.prevent="sendMessage">
    <div class="form-group">
      <label for="message">{{ $t('message') }}</label>
      <textarea v-model="message" name="message" id="message" class="form-control" rows="5"></textarea>
    </div>
    <button type="submit" class="btn btn-primary">{{ $t('submit') }}</button>
  </form>
</template>
<script>
import { mapGetters } from 'vuex'

export default {
  data () {
    return {
      slug: this.$route.params.playerSlug,
      message: ''
    }
  },
  computed: {
    ...mapGetters([
      'lang',
      'console',
      'isAuthenticated'
    ])
  },
  mounted () {
    if (!this.isAuthenticated) {
      this.$router.push({ name: 'PlayerHome', params: { lang: this.lang, console: this.console, playerSlug: this.slug } });
    }
    this.$store.dispatch('SET_MENU_PRIMARY_USER', 'home')
  },
  methods: {
    sendMessage () {
      const lang = this.$route.params.lang
      const console = this.$route.params.console
      const slug = this.$route.params.playerSlug
      const path = `auth/${lang}/console/${console}/player/${slug}/message`
      const params = {
        message: this.message
      }
      this.$axios.post(path, params).then((response) => {
        this.message = ''
        this.$toastr.success(response.data.message)
      })
    }
  }
}
</script>
